import React, {
    PropsWithChildren,
    Reducer,
    useEffect,
    useReducer,
} from "react";
import { FormRenderProps } from "react-final-form";

import RAFForm from "../../../RAFComponents/Inputs/RAFForm";
import RAFButtonComponent from "../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFDeletedRecordState from "../../../RAFComponents/Navigation/RAFDeletedRecordState";
import RAFAttributeRelatedListProvider from "../../../RAFComponents/Providers/RAFAttributeRelatedListProvider";
import { RetrieveRecord, hideProgress, showProgress } from "../../../RAFComponents/helpers/AppHelper";
import RAFPermissionRender from "../../../RAFComponents/helpers/PermissionHelper";
import { IDialogProps, isNotNullAndUndefined } from "../../../RAFComponents/helpers/utils";
import ACLookup from "../../../components/shared/ACFormFields/ACLookup";
import ACLoadingPanel from "../../../components/shared/ACLoadingPanel";
import { RAFButtonConstant, RAFLayout } from "../../../constants/Common/Constants";
import { RAFEntityName } from "../../../constants/Common/EntityConstants";
import { PermissionConstants } from "../../../constants/Common/PermissionConstants";
import RAFEntityProvider from "../../Common/Providers/RAFEntityProvider";
import { getPermissionGroupLookUpCustomFilter, saveUserAPI } from "./UserHelper";
import { UserRow } from "./UserRow";

interface IProps {
    objectUID: string;
}

interface IState {
    isLoading: boolean;
    noContent: boolean;
    userRow: UserRow;
}

function ManageUserPermissionGroup({ ...props }: PropsWithChildren<IProps & IDialogProps>) {

    let rafForm: FormRenderProps | null;

    const moduleName = RAFEntityName.User;

    const outerDivId = `manageupdate_employee_dialog`;

    const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
        (state, newState) => ({ ...state, ...newState }),
        {
            isLoading: true,
            noContent: true,
            userRow: null,
        }
    );

    useEffect(() => {
        refresh();
    }, []);

    const refresh = async () => {
        if (props.isActive) {
            setState({ isLoading: true });
            const [userRow] = await Promise.all([
                getIntitailUserRow(),
            ]);

            if (isNotNullAndUndefined(userRow)) {
                setState({ isLoading: false, noContent: false, userRow });
            } else {
                setState({ isLoading: false, noContent: true });
            }
        }
    };

    const getIntitailUserRow = () => {
        return new Promise<UserRow>(async (resolve) => {
            if (isNotNullAndUndefined(props.objectUID)) {
                const userRow = await RetrieveRecord(props.objectUID, moduleName);

                if (isNotNullAndUndefined(userRow) && isNotNullAndUndefined(userRow.UID)) {
                    resolve(userRow);
                } else {
                    resolve(null);
                }
            } else {
                resolve(null);
            }
        });
    };

    const onSubmitUser = async (value: UserRow) => {
        let progressDiv = showProgress(`#${outerDivId}`);
        const userRow = { ...value } as UserRow;
        const response = await saveUserAPI(userRow);
        hideProgress(progressDiv);
        if (isNotNullAndUndefined(response)) {
            if (isNotNullAndUndefined(props.onSave)) {
                props.onSave(response.entityId);
            }
        };
    };

    const getFooterContent = () => {
        return (
            <div className="w-100">
                <div className="row gx-2">
                    <div className="col-auto ms-auto">
                        <RAFPermissionRender permissionName={isNotNullAndUndefined(props.objectUID) ? PermissionConstants.UserUpdate : PermissionConstants.UserCreate}>
                            <RAFButtonComponent
                                type="button"
                                isPrimary
                                action={RAFButtonConstant.Save}
                                onClick={() => rafForm && rafForm.form.submit()}
                                idString="CreateContent"
                                disabled={rafForm && rafForm.submitting}
                            />
                        </RAFPermissionRender>
                    </div>
                    <div className="col-auto">
                        <RAFButtonComponent
                            type="button"
                            action={RAFButtonConstant.Cancel}
                            onClick={props.onClose}
                            idString="CreateContent"
                        />
                    </div>
                </div>
            </div>
        );
    };

    const getInputFormContent = () => {
        return (
            <div className="e-dlg-body-content">
                <div className="row gx-2 gy-4">
                    <ACLookup<UserRow> field="PermissionGroup" label="Permission Group" placeholder="Select Permission Group"
                        showFullList={false}
                        url={'PermissionGroup/Lookup'} moduleName={RAFEntityName.Role}
                        showClearButton={false}
                        customFilter={getPermissionGroupLookUpCustomFilter()}
                        required
                    />
                </div>
            </div>
        );
    };

    if (props.isActive) {
        if (state.isLoading === false) {
            if (state.noContent === false) {
                return (
                    <RAFEntityProvider moduleName={moduleName}>
                        <RAFAttributeRelatedListProvider moduleName={moduleName}>
                            <RAFForm
                                type={UserRow}
                                initialValues={state.userRow}
                                formRef={(g) => (rafForm = g)}
                                layout={RAFLayout.TwoColumnLayout}
                                onSubmit={onSubmitUser}
                                className="h-100"
                            >
                                <div className="e-dlg-content-outer" id={outerDivId}>
                                    {getInputFormContent()}
                                    <div className="e-dlg-footerContent ">
                                        {getFooterContent()}
                                    </div>
                                </div>
                            </RAFForm>
                        </RAFAttributeRelatedListProvider>
                    </RAFEntityProvider>
                );
            } else {
                return (
                    <div className="container-fluid px-0">
                        <RAFDeletedRecordState title="This item is either deleted or You do not have sufficient privileges to view this item." />
                    </div>
                );
            }
        } else {
            return (
                <div className="container-fluid px-0">
                    <ACLoadingPanel loadingText="Loading..." />
                </div>
            );
        }
    } else {
        return (
            <div></div>
        );
    }
};


export default React.memo(ManageUserPermissionGroup);
