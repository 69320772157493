import { RAFCustomFilter, RAFCustomOperator } from "../../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import { showSuccessToast, showWarningToast } from "../../../RAFComponents/Utility/RAFToastComponent";
import { IsSuperAdmin, hideProgress, removeSessionStorage, showProgress } from "../../../RAFComponents/helpers/AppHelper";
import { getSaveRequest, isNotEmptyArray, isNotNullAndUndefined, propertyOf } from "../../../RAFComponents/helpers/utils";
import { ListServiceRequest } from "../../../RAFComponents/models/Common/ListRequest";
import { LookUpRow } from "../../../RAFComponents/models/CompositeTypes/LookUpRow";
import * as repositoryActions from "../../../RAFComponents/store/actions/repositoryActions";
import { ContentType, ESIOAppAdministrator, ESIOSuperAdministrator, StorageKey } from "../../../constants/Common/Constants";
import { RoleRow } from "../../Common/Role/RoleRow";
import { UserRow } from "./UserRow";


export const enum ManageUserDlgMode {
    Details = 'details',
    Create = 'create',
    Update = 'update',
    CreateUserEmployee = 'CreateUserEmployee',
    CreateUserLinkEmployee = 'CreateUserLinkEmployee',

    CreateUserContact = 'CreateUserContact',
    CreateUserLinkContact = 'CreateUserLinkContact',

    CreateUserCareRecipient = 'CreateUserCareRecipient',
    CreateUserLinkCareRecipient = 'CreateUserLinkCareRecipient',
}

export const getAllUsersByTenantUID = (tenantUID: string) => {
    return new Promise<LookUpRow[]>((resolve) => {
        if (isNotNullAndUndefined(tenantUID)) {
            repositoryActions
                .postDataAndGetResponse(
                    "User/ListAll",
                    { TenantUID: tenantUID },
                    null,
                    ContentType.applicationJson
                )
                .then((response) => {
                    if (
                        isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data) && isNotEmptyArray(response.data.Entities)
                    ) {
                        const userList = response.data.Entities as LookUpRow[];
                        //sort userlist by name 
                        userList.sort((a, b) => a.Value.localeCompare(b.Value));
                        resolve(userList);
                    } else {
                        resolve(null);
                    }
                })
                .catch((error) => error);
        } else {
            resolve(null);
        }
    });
};

export const getUserByUsername = (userName: string) => {
    return new Promise<UserRow>((resolve) => {
        if (isNotNullAndUndefined(userName)) {
            return repositoryActions.postDataAndGetResponse(
                'User/Exists',
                { "UserName": userName },
                null,
                ContentType.applicationJson,
            ).then((response) => {
                if (isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data) && isNotNullAndUndefined(response.data.Entity)) {
                    resolve(response.data.Entity);
                } else {
                    resolve(null);
                }
            }).catch((error) => error);
        } else {
            resolve(null);
        }
    });
};

export const ResendUserInvite = (userUID: string) => {
    return new Promise<boolean>((resolve) => {
        if (isNotNullAndUndefined(userUID)) {
            let progressDiv = showProgress(
                `body`,
                true,
            );
            return repositoryActions.postDataAndGetResponse(
                'User/InviteUser',
                { "EntityId": userUID },
                null,
                ContentType.applicationJson,
            ).then((response) => {
                hideProgress(progressDiv);
                if (isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data) && response.data === true) {
                    showSuccessToast('Invite sent successfully');
                    resolve(true);
                } else {
                    showWarningToast("Sorry something went wrong !");
                    resolve(false);
                }
            }).catch((error) => error);
        } else {
            resolve(null);
        }
    });
};

export const getUserByRealtedToUID = (relatedToUID: string) => {
    return new Promise<UserRow>((resolve) => {
        if (isNotNullAndUndefined(relatedToUID)) {

            let customFilter: RAFCustomFilter = {};
            customFilter.Condition = "and";
            customFilter.Rules = [];

            let filter: RAFCustomFilter = {};
            let filterValue: string[] = [];
            filterValue.push(relatedToUID);
            filter.Operator = RAFCustomOperator.Equal;
            filter.Value = filterValue;
            filter.Field = propertyOf<UserRow>('RelatedToUID');
            customFilter.Rules.push(filter);

            let listServiceRequest = new ListServiceRequest();
            listServiceRequest.CustomFilter = customFilter;
            listServiceRequest.Skip = 0;
            listServiceRequest.Take = 0;


            return repositoryActions.postDataAndGetResponse(
                'User/List',
                listServiceRequest,
                null,
                ContentType.applicationJson,
            ).then((response) => {
                if (isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data) && isNotEmptyArray(response.data.Entities)) {
                    resolve(response.data.Entities[0]);
                } else {
                    resolve(null);
                }
            }).catch((error) => resolve(null));
        } else {
            resolve(null);
        }
    });
};

export const saveUserAPI = async (userRow: UserRow) => {
    return new Promise<{ entityId: string, objectName: string; }>(async (resolve) => {
        repositoryActions.postDataAndGetResponse(
            'User/Save',
            getSaveRequest(userRow, userRow.UID),
            null,
            ContentType.applicationJson,
        ).then((response) => {
            if (
                isNotNullAndUndefined(response) &&
                isNotNullAndUndefined(response.data) &&
                isNotNullAndUndefined(response.data.EntityId)
            ) {
                showSuccessToast("Saved successfully");
                removeSessionStorage(StorageKey.allUsers, true);
                resolve({ entityId: response.data.EntityId, objectName: response.data.ObjectName });

            } else {
                showWarningToast("Apologies, we're unable to save the record at the moment. Please try again later.!");
                resolve({ entityId: null, objectName: null });
            }
        }).catch((error) => resolve({ entityId: null, objectName: null }));
    });
};

export const getPermissionGroupLookUpCustomFilter = () => {
    let customFilter: RAFCustomFilter = {};
    customFilter.Condition = "and";
    customFilter.Rules = [];
    if (IsSuperAdmin() === false) {
        let filter: RAFCustomFilter = {};
        let filterVal: string[] = [];
        filterVal.push(ESIOAppAdministrator);
        filter.Operator = RAFCustomOperator.NotEqual;
        filter.Value = filterVal;
        filter.Field = propertyOf<RoleRow>("PermissionGroupName");
        customFilter.Rules.push(filter);

        let filter1: RAFCustomFilter = {};
        let filterVal1: string[] = [];
        filterVal1.push(ESIOSuperAdministrator);
        filter1.Operator = RAFCustomOperator.NotEqual;
        filter1.Value = filterVal1;
        filter1.Field = propertyOf<RoleRow>("PermissionGroupName");
        customFilter.Rules.push(filter1);
    } else {
        customFilter = null;
    }

    return customFilter;
};

export const checkLoginExistsByRelatedToUID = (relatedToUID: string, relatedToType: string) => {
    return new Promise<UserRow>((resolve) => {
        if (isNotNullAndUndefined(relatedToUID) && isNotNullAndUndefined(relatedToType)) {
            return repositoryActions.postDataAndGetResponse(
                'User/LoginExists',
                {
                    "RelatedToUID": relatedToUID,
                    "RelatedToType": relatedToType,
                },
                null,
                ContentType.applicationJson,
            ).then((response) => {
                if (isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data) && isNotNullAndUndefined(response.data.Entity)) {
                    resolve(response.data.Entity);
                } else {
                    resolve(null);
                }
            }).catch((error) => resolve(null));
        } else {
            resolve(null);
        }
    });
};

//UserLinkEmployeeOrContact start

class UserLinkEmployeeOrContact {
    UserName?: string;
    PermissionGroupUID?: string;
    PermissionGroup?: string;
    RelatedToUID?: string;
    RelatedTo?: string;
}

export const saveAPICreateUserLinkEmployeeOrContact = async (userRow: UserRow,
    mode: ManageUserDlgMode.CreateUserLinkContact | ManageUserDlgMode.CreateUserLinkEmployee | ManageUserDlgMode.CreateUserLinkCareRecipient
) => {
    return new Promise<{ entityId: string, objectName: string; }>(async (resolve) => {
        if (isNotNullAndUndefined(userRow) && isNotNullAndUndefined(mode)) {
            const userLinkEmployeeOrContact = new UserLinkEmployeeOrContact();
            userLinkEmployeeOrContact.UserName = userRow.UserName;
            userLinkEmployeeOrContact.PermissionGroupUID = userRow.PermissionGroupUID;
            userLinkEmployeeOrContact.PermissionGroup = userRow.PermissionGroup;
            userLinkEmployeeOrContact.RelatedToUID = userRow.RelatedToUID;
            userLinkEmployeeOrContact.RelatedTo = userRow.RelatedTo;

            const url = `User/${mode}`;
            return repositoryActions.postDataAndGetResponse(
                url,
                userLinkEmployeeOrContact,
                null,
                ContentType.applicationJson,
            ).then((response) => {
                if (
                    isNotNullAndUndefined(response) &&
                    isNotNullAndUndefined(response.data) &&
                    isNotNullAndUndefined(response.data.EntityId)
                ) {
                    showSuccessToast("Saved successfully");
                    removeSessionStorage(StorageKey.allUsers, true);
                    resolve({ entityId: response.data.EntityId, objectName: response.data.ObjectName });

                } else {
                    showWarningToast("Apologies, we're unable to save the record at the moment. Please try again later.!");
                    resolve({ entityId: null, objectName: null });
                }
            }).catch((error) => resolve({ entityId: null, objectName: null }));
        } else {
            showWarningToast("Apologies, we're unable to save the record at the moment. Please try again later.!");
            resolve({ entityId: null, objectName: null });
        }
    });
};
//UserLinkEmployeeOrContact end

//addUser login start
class AddLoginEmployeeOrContact {
    RelatedToUID?: string;
    RelatedToType?: string;
    RelatedTo?: string;
    UserName?: string;
    PermissionGroup?: string;
    PermissionGroupUID?: string;
}

export const saveAPIAddLoginEmployeeOrContact = async (userRow: UserRow,
    mode: ManageUserDlgMode.CreateUserLinkContact | ManageUserDlgMode.CreateUserLinkEmployee | ManageUserDlgMode.CreateUserLinkCareRecipient
) => {
    return new Promise<{ entityId: string, objectName: string; }>(async (resolve) => {
        if (isNotNullAndUndefined(userRow) && isNotNullAndUndefined(mode)) {
            const addLoginEmployeeOrContact = new AddLoginEmployeeOrContact();
            addLoginEmployeeOrContact.UserName = userRow.UserName;
            addLoginEmployeeOrContact.RelatedToUID = userRow.RelatedToUID;
            addLoginEmployeeOrContact.RelatedTo = userRow.RelatedTo;
            addLoginEmployeeOrContact.RelatedToType = userRow.RelatedToType;
            addLoginEmployeeOrContact.PermissionGroup = userRow.PermissionGroup;
            addLoginEmployeeOrContact.PermissionGroupUID = userRow.PermissionGroupUID;

            return repositoryActions.postDataAndGetResponse(
                'User/AddLogin',
                addLoginEmployeeOrContact,
                null,
                ContentType.applicationJson,
            ).then((response) => {
                if (
                    isNotNullAndUndefined(response) &&
                    isNotNullAndUndefined(response.data) &&
                    isNotNullAndUndefined(response.data.EntityId)
                ) {
                    showSuccessToast("Saved successfully");
                    removeSessionStorage(StorageKey.allUsers, true);
                    resolve({ entityId: response.data.EntityId, objectName: response.data.ObjectName });

                } else {
                    showWarningToast("Apologies, we're unable to save the record at the moment. Please try again later.!");
                    resolve({ entityId: null, objectName: null });
                }
            }).catch((error) => resolve({ entityId: null, objectName: null }));
        } else {
            showWarningToast("Apologies, we're unable to save the record at the moment. Please try again later.!");
            resolve({ entityId: null, objectName: null });
        }
    });
};
//addUser login end